<script>
  import P5 from '@macfja/svelte-p5'
  import Slider from './Slider.svelte'
  import Switch from './Switch.svelte'
  import Chevron from './assets/Chevron.svelte'

  let showControls = true

  // TODO add a bus trace
  let bussyVannyNess = 50
  let maxCount = 50
  let lineCount = 20
  let lineWeight = 4
  let opacity = 255 / maxCount
  let v = 25
  let clear = false

  let batteries = false
  let interior = false

  $: if (
    bussyVannyNess ||
    maxCount ||
    interior ||
    lineWeight ||
    batteries ||
    opacity ||
    v
  ) {
    makeClear()
    opacity = 255 / maxCount
  }

  let makeClear = () => {
    clear = true
  }

  let sketch = {
    setup: (p5) => {
      p5.createCanvas(800, 600)
      p5.background('#0f1011')
      p5.stroke(255, 255, 255, 20)
      p5.fill(255, 255, 255, opacity)
      p5.strokeWeight(lineWeight)
    },
    draw: (p5) => {
      if (clear === true) {
        p5.background('#0f1011')
        lineCount = 0
        clear = false
      }

      if (lineCount < maxCount) {
        lineCount++
        let isBus = p5.random(0, 100) > bussyVannyNess
        p5.strokeWeight(lineWeight)
        let chairXVar = p5.random(-v, v / 2)
        let chairYVar = p5.random(-v / 4, v / 4)
        let xVar1 = p5.random(-v, v)
        let yVar1 = p5.random(-v, v)
        let xVar2 = p5.random(-v, v)
        let yVar2 = p5.random(-v, v)
        let xVar3 = p5.random(-v, v)
        let yVar3 = p5.random(-v / 4, v / 4)
        let radiusVar = p5.random(-v / 5, v / 5)

        if (isBus) {
          // bus
          let bus = [
            { x: 50 + xVar1, y: 200 + yVar1 },
            { x: 730 + xVar2, y: 200 + yVar1 },
            { x: 740 + xVar2, y: 210 + yVar1 },
            { x: 740 + xVar2, y: 370 + yVar3 },
            { x: 120 + xVar1, y: 370 + yVar3 },
            { x: 50 + xVar1, y: 360 + yVar3 },
          ]
          p5.stroke(255, 255, 255, opacity)
          p5.fill(255, 255, 255, 0)
          p5.beginShape()
          for (let i = 0; i < bus.length; i++) {
            p5.vertex(bus[i].x, bus[i].y)
          }
          p5.endShape('close')
          // wheels
          p5.ellipse(600 + p5.random(-v, v), 360 + yVar3 / 2, 60 + radiusVar)
          p5.ellipse(
            240 + p5.random(-v * 2, v * 2),
            360 + yVar3 / 2,
            60 + radiusVar,
          )

          let chair = [
            { x: 660 + chairXVar, y: 280 + chairYVar },
            { x: 670 + chairXVar, y: 330 + chairYVar },
            { x: 696 + chairXVar, y: 329 + chairYVar },
          ]
          if (interior) {
            p5.stroke(77, 129, 230, opacity)
            p5.noFill()
            p5.beginShape()
            for (let i = 0; i < chair.length; i++) {
              p5.vertex(chair[i].x, chair[i].y)
            }
            p5.endShape()
          }
          let battery = [
            { x: 120 + xVar1, y: 368 + yVar3 },
            { x: 700 + p5.random(-v, v), y: 350 + p5.random(-v, v) },
          ]
          if (batteries) {
            p5.rectMode('corners')
            p5.stroke(122, 224, 151, opacity / 2)
            p5.fill(122, 224, 151, 0.4)
            p5.rect(battery[0].x, battery[0].y, battery[1].x, battery[1].y)
          }
        } else {
          // Chair
          let chair = [
            { x: 500 + chairXVar, y: 250 + chairYVar },
            { x: 510 + chairXVar, y: 300 + chairYVar },
            { x: 536 + chairXVar, y: 298 + chairYVar },
          ]
          if (interior) {
            p5.stroke(77, 129, 230, opacity)
            p5.noFill()
            p5.beginShape()
            for (let i = 0; i < chair.length; i++) {
              p5.vertex(chair[i].x, chair[i].y)
            }
            p5.endShape()
          }

          // Battery
          let battery = [
            { x: 203, y: 362 },
            { x: 550, y: 344 },
          ]
          if (batteries) {
            p5.rectMode('corners')
            p5.stroke(122, 224, 151, opacity / 2)
            p5.fill(122, 224, 151, 0.4)
            p5.rect(
              battery[0].x + xVar1,
              battery[0].y + yVar3,
              battery[1].x + p5.random(-v, v),
              battery[1].y + p5.random(-v, v),
            )
          }

          let van = [
            { x: 200 + xVar1, y: 215 + yVar1 },
            { x: 550 + xVar2, y: 215 + yVar1 },
            { x: 600 + xVar3, y: 315 + yVar2 },
            { x: 600 + xVar3, y: 365 + yVar3 },
            { x: 200 + xVar1, y: 365 + yVar3 },
          ]

          p5.stroke(255, 255, 255, opacity)
          p5.fill(255, 255, 255, 0)

          p5.beginShape()
          for (let i = 0; i < van.length; i++) {
            p5.vertex(van[i].x, van[i].y)
          }
          p5.endShape('close')

          p5.ellipse(280 + p5.random(-v, v), 365 + yVar3, 45 + radiusVar)
          p5.ellipse(
            550 + p5.random(-v / 4, v / 4),
            365 + yVar3,
            45 + radiusVar,
          )
        }
      }
    },
  }
</script>

<style>
  main {
    font-family: sans-serif;
    text-align: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .control-container {
    /* background-color: rosybrown; */
    align-self: stretch;
    position: relative;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 24rem;
    transition: all 400ms ease;
    box-sizing: border-box;
    padding: 0.5rem;
  }
  .controls {
    width: 23rem;
    border-radius: 0.25rem;
    background-color: #1e1f22;
    padding: 8rem 1rem 1rem 1rem;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
  .panel-title {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-family: Arrival Apercu Mono Pro, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
      sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11.25px;
    line-height: 1rem;
    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.96);
  }

  .sketch-container {
    flex: 1;
  }

  .clear {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: 0px;
    width: 100%;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    height: 4rem;
    font-family: Arrival Apercu Mono Pro, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
      sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11.25px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-feature-settings: 'zero' on;
    color: #0f1011;
    cursor: pointer;
  }

  .inputs {
    display: flex;
    flex-direction: column;
  }

  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    justify-content: center;
  }
  label {
    text-align: center;
    color: rgba(255, 255, 255, 0.96);
    font-family: Arrival Apercu Pro, -apple-system, BlinkMacSystemFont,
      'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
      sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    /* margin-bottom: 0.5rem; */
  }

  .collapse {
    opacity: 0.5;
    transition: opacity 200ms ease-in-out, transform 400ms ease-in-out;
    cursor: pointer;
    margin: 0px;
  }

  .collapse:hover {
    opacity: 1;
  }

  .hidden {
    position: relative;
    width: 20px;
    opacity: 0.2;
    overflow-x: hidden;
  }

  .flip {
    transform: scaleX(-1);
  }
</style>

<main>
  <div class="sketch-container">
    <P5 {...sketch} />
  </div>
  <div
    class="collapse"
    class:flip={!showControls}
    on:click={() => {
      showControls = !showControls
    }}>
    <Chevron fill="#ffffff" />
  </div>
  <div class="control-container" class:hidden={!showControls}>
    <div class="controls">
      <div class="panel-title">Controls</div>
      <div class="inputs">

        <div class="item">
          <label for="count">Bus : Van</label>
          <Slider
            label="bussyvannyness"
            min="1"
            max="100"
            bind:value={bussyVannyNess} />
        </div>

        <div class="item">
          <label for="count">Count</label>
          <Slider label="count" min="1" max="100" bind:value={maxCount} />
        </div>

        <div class="item">
          <label for="variability">Variability</label>
          <Slider label="variability" min="1" max="50" bind:value={v} />
        </div>
        <div class="item">
          <label for="lineweight">Line Weight</label>
          <Slider label="count" min="1" max="20" bind:value={lineWeight} />

        </div>
        <!-- <div class="item">
          <label for="lineweight">Line Opacity</label>
          <Slider label="count" min="1" max="255" bind:value={opacity} />
        </div> -->
        <div class="item">
          <label for="interior">Interior</label>
          <Switch bind:value={interior} label="Interior" />
        </div>
        <div class="item">
          <label for="batteries">Batteries</label>
          <Switch bind:value={batteries} label="Batteries" />
        </div>

      </div>
      <button class="clear" on:click={makeClear}>re–draw</button>

    </div>
  </div>
</main>
