<script>
  export let label
  export let value
  export let min
  export let max
</script>

<style>
  input[type='range'] {
    -webkit-appearance: none;
    height: 0.5rem;
    outline: none !important;
    appearance: none;
    border: none;
    background: none;
    border-radius: 0;
  }

  input[type='range']:hover {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    outline: none;

    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-bottom: 0.7rem solid #ffffff;

    margin-top: 0.8rem;
  }

  input[type='range']::-webkit-slider-runnable-track {
    height: 0.4rem;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.16) 10%,
      rgba(255, 255, 255, 0.16) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  input[type='range']::-ms-track {
    height: 0.4rem;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.16) 10%,
      rgba(255, 255, 255, 0.16) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 0.4rem;
    cursor: pointer;
    animate: 0.2s;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.16) 10%,
      rgba(255, 255, 255, 0.16) 90%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  input[type='range']::-moz-range-thumb {
    cursor: pointer;
    outline: none;
    width: 0;
    height: 0;
    background: none;
    border-radius: 0;
    border-top: 1.8rem solid transparent;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid transparent;
    border-bottom: 0.7rem solid #ffffff;
  }
</style>

<input id={label} type="range" {min} {max} bind:value />
