<script>
  export let fill = '0F1011'
</script>

<style>
  svg {
    transform: rotate(90deg);
  }
</style>

<svg
  width="24"
  height="24"
  viewBox="0 0 16 16"
  {fill}
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M3 10.5L3.70711 11.2071L7.85355 7.06065L12 11.2071L12.7071 10.5L7.85355
    5.64643L3 10.5Z" />
</svg>
